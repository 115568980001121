.contact-card {
    padding:30px 30px 30px 30px !important;
    border-style: solid;
}

.right-align {
    text-align: right;
}

.remove-margin {
    margin:0
}

.small-margin {
    margin-bottom: 5px;;
}

.small-text{
    font-size: 14px;
}