
.logo {
    font-family: p-semibold;
    font-size: larger;
}

.num-col {
    width: 35px;
}

/* table {
    position: absolute;
    width: 100vw; 
    top: 40%;
    left: -50px;
} */

/* td {
    position: relative;
    left: 50px;
}

td > h6 {
    margin-bottom: 0;
} */

/* tr {
    border-bottom: 1px white solid;
    position: relative;
    top: -5px;
    margin-bottom: 5px;
} */

.carousel-col {
    overflow: hidden;
}

.carousel-container {
    position: relative;
    top: 10%;
    left: 20px;
}

.copyright {
    color: var(--second-font-color);
    font-size: 10px;
    transform: rotate(-90deg);
    left: -50%;
    top: -450px;
    position: relative;
}

.category-title{
    font-size: 12px;
    position: relative;
    top: 8%;
    left: 20px;
}

.last-col {
    display: flex;
    align-items: flex-end;
}

.contact {
    margin-left: 50px;
}
/* .page-title {
    height:25vh;
    margin-top:3vh;
    margin-bottom: 3vh;
    display: flex;
    justify-content: center;
    align-items: center;
} */

.large-text {
    font-size: 100px;
}