.full-height {
    height: 100vh;
}

.left-align {
    text-align: justify;
}

.small-text {
    font-size:8px;
}