.menu-container{
    height: 100vh;
}

.active-link {
    font-weight: 900;
    /* text-decoration: underline; */
    color:rgb(255, 255, 255)
}
.dim-text {
    font-weight: 100;
    color:rgba(255, 255, 255, 0.801);
}

  
.tr-underline {
    border-bottom: 1px solid white;
}


.menu {
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    height: 93vh;
    position: fixed;

}

.margin-right {
    margin-right:20px;
}
.right-menu {
    display:flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    height: 93vh;
    position: fixed;
}

.page-header-menu{
    display: flex;
}