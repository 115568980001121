.App {
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding: 25px;
  display: flex;
}

.row {
  display: flex;
}
