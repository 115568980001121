.text-align{
    text-align: right;
    
}
.p-style {
    font-weight: lighter;
    font-size: 14px;
}
.picture-spacing{
    margin-bottom: 15%;
}

.main-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.page-title {
    height:auto;
    margin-top:6vh;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.title {
    font-size: 20px;
}


.max-height {
    height:100vh;
}

.center-content {
   display: flex;
   justify-content: center;
   flex-direction: column;
}

.mobile-homepage-title{
    text-align: right;
    margin-top: 10px;
}