:root {
  --main-bg-color: black;
  --main-font-color: white;
  --second-font-color: #bdbdbd;
}
body{
  background-color: var(--main-bg-color);
}

@font-face { 
  font-family: p-black; 
  src: url('./fonts/Poppins-Black.ttf'); 
  font-display: block;
}

@font-face { 
  font-family: p-bold; 
  src: url('./fonts/Poppins-Bold.ttf');
  font-display: block; 
}

@font-face { 
  font-family: p-semibold; 
  src: url('./fonts/Poppins-SemiBold.ttf');
  font-display: block; 
}

@font-face { 
  font-family: p-medium; 
  src: url('./fonts/Poppins-Medium.ttf');
  font-display: block; 
} 

@font-face { 
  font-family: p-regular; 
  src: url('./fonts/Poppins-Regular.ttf');
  font-display: block; 
} 

@font-face { 
  font-family: p-light; 
  src: url('./fonts/Poppins-Light.ttf');
  font-display: block; 
}

#root {
  font-family: p-regular;
  color: var(--main-font-color);
  background-color: var(--main-bg-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  /* height: 100vh; */
  overflow:'hidden'
}

a {
  color: white !important;
  text-decoration: none !important ;
}

a:hover {
  color: var(--second-font-color) !important;
  text-decoration: none !important;
}

.container, .container-fluid, .row {
  height: 100%;
}

#loadOverlay{display: none;}